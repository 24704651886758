import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const VisionBox = ({faicon, title, des}) => {
  return (
    <div className='vs-card'>
        <div className='icon-align'>
            <FontAwesomeIcon icon={faicon}/>
        </div>
        <h2>{title}</h2>
        <p>{des}</p>
    </div>
  )
}

export default VisionBox