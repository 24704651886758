import React, {useEffect} from 'react'
// import { Link } from 'react-router-dom'
import './Serve.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

const Serve = () => {

  // browser tab title
  useEffect(() => {
    document.title = 'Serve - TACTICA Ministries';
  }, []);

  return (
  <div className='serve-pg'>

    <div className='header-section-sv'>
      <div className='container'>
        <Navbar/>
        <div className='text-box-sv'>
          <h1 className='sv-h1'>Apply to Serve in the Ministry</h1>
          <div className='serve-btns'>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdQ4qR3-jZxTXtv7pYEojp5ySn6xY0KcdN630mBekEserlSPw/viewform" className='btn-sv' target='_blank'  rel="noreferrer noopener" aria-label='Application-form-link'>Application Form</a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdVXhCrnvdoDlJ8yuo--GXhp31JnNbHIhUytHGB0AXh4sPIPw/viewform?usp=sf_link" className='btn-sv' target='_blank'  rel="noreferrer noopener" aria-label='Application-form-link'>Spouse Form</a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfl67yx4knQfasQipn7Ng5BOpUs49ZbirxeSQq9hyfmrBBkYQ/viewform?usp=sf_link" className='btn-sv' target='_blank'  rel="noreferrer noopener" aria-label='Application-form-link'>Pastor Form</a>
          </div>
        </div>

      </div>
    </div>

    <Footer/>
    
  </div>
  )
}
export default Serve