import {toast} from 'react-toastify'

const apiKey= process.env.REACT_APP_WEB3FORMS_API_KEY

export const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", apiKey); //andres: efae9b3c-bf15-4bd7-91ed-ff9435feb4d9 //tactica a517fe6f-3adc-4cd6-9b6c-04d38f98b600

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      toast.success("Email successfully sent.")
      event.target.reset();
    }
    else {
      console.log("Error", res)
      toast.error("Error while sending email.")
    }
  };