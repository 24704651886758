import React, {useEffect} from 'react'
import './Shop.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

const Shop = () => {

    // link to proforma website
    const handleShopBtn = () => {
        const proformaURL = 'https://tacticaministries.buyproforma.com/#/';

        window.open(proformaURL, '_blank')
    }

    // browser tab title
  useEffect(() => {
    document.title = 'Shop - TACTICA Ministries';
  }, []);


  return (
    <div className='shop-pg'>
        <div className='header-sh'>
            <div className='container'>
              <Navbar/>
              <div className='text-box'>
                  <h1>TACTICA Shop</h1>
                  <div className='cata-align'>
                    <a href='#shopbutton' className='shop-btn' onClick={handleShopBtn}>Browse our catalogue</a>
                  </div>
              </div>
            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default Shop