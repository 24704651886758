import React, { useRef, useEffect } from 'react'
import './Give.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import donImg1 from './Images/donation-pic1.jpg'
import donImg2 from '../../Images/ryan-gretchen-rought-2023-2.jpg'
import donImg3 from './Images/donation-pic3.JPG'
import donImg4 from './Images/donation-pic4.jpg'
import donImg5 from './Images/donation-pic5.jpg'
import DonBox from '../../Components/Divs/DonBox'

const Give = () => {

  // browser tab title
  useEffect(() => {
    document.title = 'Give - TACTICA Ministries';
  }, []);

  // Links to donate
  const paypalUrl = 'https://www.paypal.com/paypalme/tacticaministries?country.x=US&locale.x=en_US'
  const abweFirstUrl = 'https://give.abwe.org/checkout?page=1287'
  const abweSecondUrl = 'https://give.abwe.org/checkout?page=501'

  // Donations data
  const donationTypeData = [
    {
      img: donImg1,
      alt: 'don-card1',
      place: 'TACTICA Ministries',
      click: paypalUrl,
      clickTxt: 'Donate Now'
    },
    {
      img: donImg2,
      alt: 'don-card2',
      place: 'Ryan & Gretchen Rought',
      click: abweFirstUrl,
      clickTxt: 'Donate Now'
    },
    {
      img: donImg3,
      alt: 'don-card3',
      place: 'TACTICA Equipment',
      link: '/Equipment',
      clickTxt: 'Learn More'
    },
    {
      img: donImg4,
      alt: 'don-card4',
      place: 'Bible Project',
      click: paypalUrl,
      clickTxt: 'Donate Now'
    },
    {
      img: donImg5,
      alt: 'don-card5',
      place: 'TACTICA Teams',
      click: abweSecondUrl,
      clickTxt: 'Donate Now'
    },
  ]

  // Scroll effect
  const missStatRef = useRef(null);
  
  const handleScrollToMissStat = () => {
    missStatRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
  <div>

    <div className='header-section-gv'>
      <div className='container'>
        <Navbar/>
        <div className='text-box-gv'>
          <h1 className='title-gv'>Ministry Giving</h1>
          <div className='give-align'>
            <button className='btn-gv-1' onClick={handleScrollToMissStat}>View Giving Options</button>
          </div>
        </div>
      </div>
    </div>

    <div className='donation-gv' ref={missStatRef}>
      <div className='container'>
      <h1>Make a Donation Today</h1>
        <div className='don-texts'>
          <p>There are a variety of ways to come alongside TACTICA Ministries. Explore below the following giving opportunities: Support the TATICA general ministry fund; provide funding for the personal ministry of TACTICA's founders, Ryan and Gretchen Rought; Donate equipment, gear, apparel, and/or merchandise to be used in the ministry; Give towards the TACTICA Bible project; Make a donation for an individual participating on a TACTICA mission trip.</p>
          <br />
          <p><b>TACTICA Ministries is a non-profit, tax-exempt, 501(c)(3) organization.</b> Please note: Donation processing for the personal ministry of Ryan & Gretchen Rought as well as donations for short term mission team members are handled by the mission organization ABWE; your receipt for tax purposes will come from ABWE when making a donation to Ryan & Gretchen Rought or a TACTICA Team.</p>
        </div>
      </div>
    </div>

    <div className='donation-cards-gv'>
      <div className='container'>
        <div className='don-row'>
          {donationTypeData.map((terms, index) => (
            <DonBox
              key={index}
              link={terms.link}
              img={terms.img}
              alt={terms.alt}
              place={terms.place}
              click={terms.click}
              clickTxt={terms.clickTxt}
            />
          ))}
        </div>
      </div>
    </div>

    <Footer/>

  </div>
  )
}
export default Give