import React, {useEffect} from 'react'
import './Contact.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { onSubmit } from '../../utils.js'

const Contact = () => {

  // browser tab title
  useEffect(() => {
    document.title = 'Contact - TACTICA Ministries';
  }, []);

  // Icon library
  library.add(faPhone, faEnvelope)

  return (
  <div>
    <div className='header-section-cont'>
      <div className='container'>
        <Navbar/>
        <div className='contact-head'>
          <h1>Contact Us</h1>
        </div>
      </div>
    </div>
    
    <div className='contact-section'>
      <div className='container'>
        <div className='contact-dub-info'>
          <div className='contact-det'>
            <h1>TACTICA Ministries</h1>
            <h2>5611 Springridge St. Portage, MI, USA 49024</h2>
            <p className='phone-num'><FontAwesomeIcon icon={faPhone}/>&nbsp; +1 (269) 993 - 4813</p>
            <br />
            <p className='email'><FontAwesomeIcon icon={faEnvelope}/>&nbsp; tactica3@yahoo.com</p>
          </div>

          <div className='contact-fm'>
            <form action="" onSubmit={onSubmit}>
              <input type="text" name='Name' placeholder='Name' required/>
              <input type="email" name='Email' placeholder='Email' required />
              <input type="text" name='Subject' placeholder='Subject' required/>
              <textarea name="Message" id="" rows="6" placeholder='Message'></textarea>
              <button type='submit'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
  )
}
export default Contact