import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Blog.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import artPic1 from './Images/article-pic1.jpg'
import artPic2 from './Images/article-pic2.png'
import artPic3 from './Images/article-pic3.jpg'
import artPic4 from './Images/article-pic4.png'
import artPic5 from './Images/article-pic5.jpg'
import artPic6 from './Images/article-pic6.png'
import artPic7 from './Images/article-pic7.png'
import artPic8 from './Images/article-pic8.png'
import { onSubmit } from '../../utils.js'

import 'react-toastify/dist/ReactToastify.css';

const Blog = () => {

  // browser tab title
  useEffect(() => {
    document.title = 'Blog - TACTICA Ministries';
  }, []);

  // Scroll effect
  const missStatRef = useRef(null);
  
  const handleScrollToMissStat = () => {
    missStatRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Hide and show extra blog entries
  const [showExtraBlogs, setShowExtraBlogs] = useState(false);

  const handleClick = () => {
    setShowExtraBlogs(true); 
  };

  const handleHideClick = () => {
    setShowExtraBlogs(false);
  };

  return (
  <div className='blog-pg'>

    <div className='header-section-blg'>
      <div className='container'>
        <Navbar/>
          <div className='text-box-blg'>
            <h1 className='title-blg'>TACTICA Testimonies</h1>
            <button className='btn-blg' onClick={handleScrollToMissStat}>Read More</button>
          </div>
      </div>
    </div>

    <div className='newsletter-sect' ref={missStatRef}>
      <div className='container'>
        <h1>Sign Up to Receive TACTICA News</h1>
        <p>You will receive notifications when we post new content.</p>

        <div className='form-box'>
          <form action="" onSubmit={onSubmit}>
          <input type="text" name='Email-for-TACTICA-news' placeholder='Enter your email' style={{background: 'white'}} />
          <button type='submit'>Submit</button>
          </form>
        </div>
      </div>
    </div>

    <div className='blog-posts'>
      <div className='container'>
        <div className='blog-post'>
            <div className='blog' id='1'>
              <img src={artPic1} alt="Burdened for those silently suffering" />
                <h3>Daily Prayer</h3>
                <h2>Burdened For Those Silently Suffering...</h2>
                <p>Headlines across the country remind us that the weight, stress, and trauma of the public safety career are aspects we...</p>
                <div className='read-more-btn'>
                  <Link to="/burdened-for-those-silently-suffering-in-the-public-safety-community">Read More</Link>
                </div>
            </div>

            <div className='blog' id='2'>
              <img src={artPic2} alt="a glance into the work of the ecuadorian" />
                <h3>Ministry Work</h3>
                <h2>A Glance Into The Work Of The Ecuadorian...</h2>
                <p>TACTICA has provided tactical, medical, and leadership training on numerous occasions to the Ecuadorian GOE team...</p>
                <div className='read-more-btn'>
                  <Link to="/a-glance-into-the-work-of-the-ecuadorian-special-operations-group">Read More</Link>
                </div>
            </div>

            <div className='blog' id='3'>
              <img src={artPic3} alt="summary of a recent conversation burnout is real" />
                <h3>Reflections</h3>
                <h2>Summary Of A Recent Conversation: Burnout...</h2>
                <p>Toxic but common lies- Big boys don't cry. Real men are not emotional. If you “feel” the wrong thing, you are defective.</p>
                <div className='read-more-btn'>
                  <Link to="/summary-of-a-recent-conversation-burnout-is-real">Read More</Link>
                </div>
            </div>

            <div className='blog' id='4'>
              <img src={artPic4} alt="tactica mens gathering november 2023" />
                <h3>Daily News</h3>
                <h2>TACTICA Men's Gathering: Nov 2023</h2>
                <p>The TACTICA Ministry Center was recently the site for a men's gathering. The building was full of life and food and relationships...</p>
                <div className='read-more-btn'>
                  <Link to="/tactica-mens-gathering-november-2023">Read More</Link>
                </div>
            </div>

            <div className='blog' id='5'>
              <img src={artPic5} alt="tactical medicine & the Gospel for Paramedics" />
                <h3>Testimonies</h3>
                <h2>Tactical Medincine & The Gospel For Paramedics</h2>
                <p>TACTICA Force Multiplier partners recently provided training at the university for new paramedics...</p>
                <div className='read-more-btn'>
                  <Link to="/tactical-medicine-&-the-gospel-for-paramedics">Read More</Link>
                </div>
            </div>

            <div className='blog' id='6'>
              <img src={artPic6} alt="all because he collided with jesus" />
                <h3>Testimonies</h3>
                <h2>All Because He COLLIDED With JESUS</h2>
                <p>TACTICA's influence on the Costa Rican police model has been unusual. Ryan and his family arrived...</p>
                <div className='read-more-btn'>
                  <Link to="/all-because-he-collided-with-jesus">Read More</Link>
                </div>
            </div>

            {showExtraBlogs && (
              <>
                <div className='blog' id='7'>
                  <img src={artPic7} alt="GOE Commanders trained in swatt tactics" />
                    <h3>Daily News</h3>
                    <h2>GOE Commanders Trained In SWAT Tactics</h2>
                    <p>Guano - The afternoon of this Friday, September 18, at the Special Operations Group of the Special Operations Group...</p>
                    <div className='read-more-btn'>
                      <Link to="/goe-commanders-trained-in-swatt-tactics-with-us-instructors-from-tactica-ministries">Read More</Link>
                    </div>
                </div>

                <div className='blog' id='8'>
                  <img src={artPic8} alt="police force faith intertwine" />
                    <h3>Daily News</h3>
                    <h2>Police Force, Faith Intertwine...</h2>
                    <p>Portage - More than 40 men and women who help make society a safer place were the focus of attention Sunday at Berean...</p>
                    <div className='read-more-btn'>
                      <Link to="/police-force-faith-intertwine-as-portages-berean-baptist-church-honors-law-enforcement">Read More</Link>
                    </div>
                </div>
              </>
            )}

          </div>

          {!showExtraBlogs ? (
            <div className='expand-btn'>
              <button onClick={handleClick}>Click to Expand</button>
            </div>

          ) : (
            <div className='hide-btn'>
              <button onClick={handleHideClick}>Click to Hide</button>
            </div>
          )}

      </div>
    </div>

    <Footer/>
    
  </div>

  )
}

export default Blog