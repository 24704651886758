import React from 'react'

const CoreBox = ({imgSrc, altSrc, title, description}) => {
  return (
    <div className='card'>
        <img src={imgSrc} alt={altSrc} />
        <h2>{title}</h2>
        <p>{description}</p>
    </div>
  )
}

export default CoreBox