import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import "./Home.css"
import card1 from "./Images/card-pic1.JPG"
import card2 from "./Images/card-pic2.jpg"
import card3 from "./Images/card-pic3.JPG"
import card4 from "./Images/card-pic4.jpg"
import vid1 from "./Images/tactica-into.mp4"
import vid2 from "./Images/tactica-ecuador-oct-2023.mp4"
import vid3 from "./Images/tactica-naples.mp4"
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import CoreBox from '../../Components/Divs/CoreBox';

function Home() {

  // browser tab title
  useEffect(() => {
    document.title = 'TACTICA Ministries';
  }, []);

  // video slider functionality
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <div style={{ textAlign: "center" }}>
      <ul style={{ margin: "0px", padding: "0px", display: "inline-block", listStyle: "none" }}> 
        {React.Children.map(dots, dot => (
          <li style={{ display: "inline-block", margin: "0 15px" }}>{dot}</li>
        ))}
      </ul>
    </div>
    )
  };

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    );
  }
  
  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowLeft}/>
      </div>
    );
  }

  // Core values data
  const coreValsData = [
    {
      imgSrc: card1,
      altSrc: 'card1',
      title: 'Integrity',
      description: 'TACTICA is committed to truth and transparency in our relationships as we strive to be men and women of integrity.'
    },
    {
      imgSrc: card2,
      altSrc: 'card2',
      title: 'Excellence',
      description: 'TACTICA is committed to effectiveness and excellence, fleeing from apathy; mediocrity is not an option.'
    },
    {
      imgSrc: card3,
      altSrc: 'card3',
      title: 'Compassion',
      description: 'TACTICA is committed to honoring God through intentional relationships that reflect compassion, mercy, and grace.'
    },
    {
      imgSrc: card4,
      altSrc: 'card4',
      title: 'Accountability',
      description: 'TACTICA is committed to spiritual, financial & ministerial transparency in the stewardship of our resources.'
    }
  ];


  return (
    <div className='home-pg'>
      
      <div className='header-sect'>
        <div className='container'>
          <Navbar/>
          <div className='text box'>
            <h1 className='title-hm'>Training Heroes for Christ in the Public Safety <br /> Community</h1>
            <div className='home-btns-nav'>
              <Link to="/Serve" className='home-btn'>Get Started</Link>
              <Link to="/Give" className='home-btn'>Give Today</Link>
            </div>
          </div>
        </div>
      </div>

      <div className='core-vals'>
        <div className='container'>
        
          <h1 className='core-vals-h1'>Our Core Values</h1>
          
          <div className='core-cards'>
              {coreValsData.map((info, index) => (
                <CoreBox
                  key={index}
                  imgSrc={info.imgSrc}
                  altSrc={info.altSrc}
                  title={info.title}
                  description={info.description}
                />
              ))}
          </div>

          <div className='core-val-btns'>
              <Link to="/About" className='core-val-btn'>Learn More</Link>
          </div>
        </div>
      </div>

      <div className='tactica-vids'>
        <div className='container'>

          <div className='title-vids'>
            <h1>TACTICA Ministries Media</h1>
          </div>

          <div className='video-carousel'>
            <Slider {...settings}>
              <div className='frame'>
                <video controls className='video-element' preload='auto'>
                  <source src={vid1} type='video/mp4' />
                </video>
              </div>

              <div className='frame'>
                <video controls className='video-element' preload='auto'>
                  <source src={vid2} type='video/mp4' />
                </video>
              </div>

              <div className='frame'>
                <video controls className='video-element' preload='auto'>
                  <source src={vid3} type='video/mp4' />
                </video>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}
export default Home