import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Login from './Pages/Login/Login'
import ForgotPass from './Pages/Forgot_Password/ForgotPass';
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Blog from './Pages/Blog/Blog'
import Serve from './Pages/Serve/Serve'
import Give from './Pages/Give/Give'
import Equipment from './Pages/Give/Equipment'
import Global from './Pages/Global/Global'
import Sog from './Pages/Global/Sog'
import Contact from './Pages/Contact/Contact'
import Shop from './Pages/Shop/Shop'
import BlogOne from './Pages/Blog_Posts/BlogOne'
import BlogTwo from './Pages/Blog_Posts/BlogTwo'
import BlogThree from './Pages/Blog_Posts/BlogThree'
import BlogFour from './Pages/Blog_Posts/BlogFour'
import BlogFive from './Pages/Blog_Posts/BlogFive'
import BlogSix from './Pages/Blog_Posts/BlogSix'
import BlogSeven from './Pages/Blog_Posts/BlogSeven'
import BlogEight from './Pages/Blog_Posts/BlogEight'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/> } />
          <Route path='/Login' element={<Login/> } />
          <Route path='/Forgot-Password' element={<ForgotPass/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path='/Blog' element={<Blog/>}/>
          <Route path='/Serve' element={<Serve/>}/>
          <Route path='/Give' element={<Give/>}/>
          <Route path='/Equipment' element={<Equipment/>}/>
          <Route path='/Global' element={<Global/>}/>
          <Route path='/Sog' element={<Sog/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/Shop' element={<Shop/>}/>
          <Route path='/burdened-for-those-silently-suffering-in-the-public-safety-community' element={<BlogOne/>}/>
          <Route path='/a-glance-into-the-work-of-the-ecuadorian-special-operations-group' element={<BlogTwo/>}/>
          <Route path='/summary-of-a-recent-conversation-burnout-is-real' element={<BlogThree/>}/>
          <Route path='/tactica-mens-gathering-november-2023' element={<BlogFour/>}/>
          <Route path='/tactical-medicine-&-the-gospel-for-paramedics' element={<BlogFive/>}/>
          <Route path='/all-because-he-collided-with-jesus' element={<BlogSix/>}/>
          <Route path='/goe-commanders-trained-in-swatt-tactics-with-us-instructors-from-tactica-ministries' element={<BlogSeven/>}/>
          <Route path='/police-force-faith-intertwine-as-portages-berean-baptist-church-honors-law-enforcement' element={<BlogEight/>}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer/>
    </div>
  );
}

export default App;
