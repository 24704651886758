import React from 'react'
import './Footer.css'
import logo from '../Assets/tactica-global-real2.png'
import { Link } from 'react-router-dom'

const Footer = () => {

  // Logo home page functionality
  const handleLogoClick = () => {
    window.location.href = '/';
  };


  return (
    <div className='footer'>
      <div className='container-footer'>
      <div className='top'>
        <nav>
          <img src={logo} alt="logo" className='logo-bottom' onClick={handleLogoClick} />
          <ul className='menu'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/About">About</Link></li>
            <li><Link to="/Blog">Blog</Link></li>
            <li><Link to="/Serve">Serve</Link></li>
            <li><Link to="/Give">Give</Link></li>
            <li><Link to="/Global">Global</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
            <li><Link to="/Shop">Shop</Link></li>
          </ul>
        </nav>
      </div>

      <div>
        <hr  className='footer-line'/>
      </div>

      <div className='bottom'>
       <p className='line'>©️ 2024 TACTICA Ministries, Inc. &nbsp; • &nbsp; Privacy Policy &nbsp; • &nbsp; Terms & Conditions</p>
       <p className='line'>Tactica Ministries, Inc. (T.A.C.T.I.C.A) is a registered 501(c)3 non-profit organization.</p>
       <p className='line'>Donations are tax deductible in full or in part, as determined by the IRS.</p>
      </div>
      </div>
    </div>
  )
}

export default Footer