import React from 'react'
import { Link } from 'react-router-dom'

const DonBox = ({img, alt, place, click, clickTxt, link}) => {

    const handleButtonLink = (url) => {
        window.open(url, '_blank')
      }

  return (
    <div className='don-card'>
        <img src={img} alt={alt} />
        <h2>{place}</h2>
        <div className='don-btn-align'>
              {link ? (
                  <Link to={link} className='btn-card-gv'>Learn More</Link>
                ) : (
            <button className='btn-card-gv' onClick={() => handleButtonLink(click)}>{clickTxt}</button>)}
        </div>
    </div>
  )
}

export default DonBox