import React, {useState} from 'react'
import './Navbar.css'
import 'react-toastify/dist/ReactToastify.css';
import logo from '../Assets/tactica-global-real2.png'
import { Link } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {

  // Menu functionality
  const [showMenu, setShowMenu] = useState(false); // State to track menu visibility

  // Toggle menu visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Logo home page functionality
  const handleLogoClick = () => {
    window.location.href = '/';
  };

  // Icon library
  library.add(faXmark, faBars)

  return (
    <div className='navbar'>
      <nav>
          <img src={logo} alt="TACTICA-Ministries-Logo" className='main-logo' onClick={handleLogoClick} />
        <ul className={showMenu ? "active" : ""}>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/About'>About</Link></li>
          <li><Link to='/Blog'>Blog</Link></li>
          <li><Link to='/Serve'>Serve</Link></li>
          <li><Link to='/Give'>Give</Link></li>
          <li><Link to='/Global'>Global</Link></li>
          <li><Link to='/Contact'>Contact</Link></li>
          <li><Link to='/Shop'>Shop</Link></li>
          <FontAwesomeIcon icon={faXmark} onClick={toggleMenu}/>
        </ul>
        <FontAwesomeIcon icon={faBars} onClick={toggleMenu}/>
      </nav>
    </div>
  )
}

export default Navbar